import * as React from 'react'
import { graphql } from 'gatsby'
import RichText from '../../components/richText';
import { GatsbyImage } from "gatsby-plugin-image"


export const SimpleCarousel = ({ slice }) => {

  return (

    slice.items.length > 0 &&
    <div className="c-simple-carousel js-simple-carousel js-carousel__notInit">
      <ul className="c-simple-carousel__list js-carousel">
        {slice.items.map((item, i) => {

          return(
          <li key={i} className={`c-simple-carousel__item ${i != 0 ? 'c-info-carousel__item--notInitHide' : ''}`}>
            <figure className="c-simple-carousel__holder">
              {item.simple_carousel_image && item.simple_carousel_image.url &&
                <GatsbyImage className="c-simple-carousel__pic c-pic" image={item.simple_carousel_image.gatsbyImageData} />
              }
              <figcaption className="c-simple-carousel__info c-content">
                <RichText render={item.simple_carousel_content.richText} />
              </figcaption> {/* .c-simple-carousel__info */}
            </figure> {/* .c-simple-carousel__holder */}
          </li>
          )
        })}

      </ul> {/* .c-simple-carousel__list */}
    </div>

  )

}



export const query = graphql`
  fragment PostDataBodySimpleCarousel on PrismicCaseDataBodySimpleCarousel {
        
    items {
      simple_carousel_content {
        richText
      }
      simple_carousel_image {
        url
        gatsbyImageData(
          
          breakpoints: [414,767,768,980,1024,1440,1680,1920]
        )

      }
    }

  }
`