import * as React from 'react'
import { graphql } from 'gatsby'
import RichText from '../../components/richText';


export const SideList = ({ slice }) => {

  return (


    slice.items.length > 0 &&

    <ul className="c-side-list">
      {slice.items.map((item, i) => {

        return (
        <li key={i} className="c-side-list__item c-content">
          <div className="c-side-list__title c-title">
            <h4>{item.side_list_title}</h4>
          </div> {/* .c-side-list__title c-title */}

          {item.side_list_content &&
            <div className="c-side-list__content">
              <RichText render={item.side_list_content.richText} />
            </div>
          }
        </li>
        )
      })}
    </ul>

  )

}



export const query = graphql`
  fragment PostDataBodySideList on PrismicCaseDataBodySideList {    
      items {
        side_list_title
        side_list_content {
          richText
        }
      }

  }
`