
import React, { useEffect } from "react"
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout';
import Seo from '../components/seo';
import Helmet from "react-helmet"
import { withPrefix } from 'gatsby';
import { SliceZone } from "@prismicio/react";
import { components } from '../slices/case'
import RichText from '../components/richText';
import { GatsbyImage } from "gatsby-plugin-image"

import ImgResolver from "../utils/imgResolver";
import { useLocation } from "@reach/router";




const Case = ({ data, pageContext }) => {


  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const { href } = useLocation();

  //Patch data
  if (!data) return null

  const $metaData = data.prismicMetadata.data;
  const $cta = data.prismicCalltoaction ? data.prismicCalltoaction.data : null;
  const $footer = data.prismicFooter.data;
  const $clutch = data.prismicClutch ? data.prismicClutch.data : null;
  const $socials = data.prismicSocials ? data.prismicSocials.data : null;
  const $contactUs = data.prismicContactUs ? data.prismicContactUs.data : null;

  const $case = data.prismicCase.data;


  const $caseTestimony = $case.case_testimony && $case.case_testimony.document ? $case.case_testimony.document.data : '';
  const $caseClient = $caseTestimony && $caseTestimony.body ? $caseTestimony.body[0] : ''


  console.log($case.hero_gradient_background )

  return (

    <Layout cta={$cta} footer={$footer} clutch={$clutch} socials={$socials} contactUs={$contactUs} headerTransparent={true}>

      <Seo metadata={$metaData} title={$case.case_title ? $case.case_title : null} description={$case.case_description.text ? $case.case_description.text : null} keywords={$case.page_keywords ? $case.page_keywords : null}></Seo>

      <Helmet>


        {/* No inline CSS */}
        <link href={withPrefix('../../assets/css/sitio/tiny-slider.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/tiny-slider-custom.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/case.info.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/case.general.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/case.grids.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/case.content.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/case.fullscreen-img.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/case.icon-list.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/case.number-list.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/case.side-list.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/case.info-carousel.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/case.simple-carousel.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/case.result.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/case.share.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/components.quote.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/case.nav.css')} rel="stylesheet" type="text/css" />

        {hasMounted && <script src="../../assets/js/sitio/case.js" />}

      </Helmet>

      <main>

        <article className="c-case">

          {/* HERO */}
          <div className="c-case__hero c-case-hero o-section--b">
            
          
            <div className="c-case-hero__holder" style={$case.hero_gradient_background ? { background: $case.hero_gradient_background } : {} }>
              <div className="c-case-hero__wrapper o-wrapper o-section--t">
                <h2 className="c-case-hero__title u-alt-font">
                  <span className="u-hidden-for-seo">Case study: </span>
                  {$case.case_title}
                </h2>
                <div className="c-case-hero__tagline u-alt-font--bold">
                  <RichText render={$case.hero_title.richText} />
                </div> {/* .c-case-hero__tagline */}

                {$case.case_filters.length > 0 &&
                  <ul className="c-case-hero__services u-alt-font--bold">
                    {$case.case_filters.map((filter, i) => (
                      filter.case_filter.document && <li key={i} className="c-case-hero__service">{filter.case_filter.document.data.filter_name}</li>
                    ))}
                  </ul>
                }

                {/*

                <div className="c-case-tech c-case-tech--hero">
                  <h3 className="c-case-tech__title u-alt-font--bold">Technologies &amp; Tools:</h3>
                  <ul className="c-case-tech__list">
                    <li className="c-case-tech__item" style={{ fontSize: '38px' }}>
                      <img src="assets/images/temp/tech/tech-2.png" width={38} height={53} title="(Nombre)" alt="(Nombre)" className="c-case-tech__logo" />

                    </li> 
                  </ul>
                </div>
              */}

              </div> {/* .c-case-hero__wrapper */}

              {$case.hero_image && $case.hero_image.url &&
                <GatsbyImage className="c-case-hero__pic c-pic" image={$case.hero_image.gatsbyImageData} />
              }
              {!$case.hero_image.url && $case.case_image &&  $case.case_image.url && 
                <GatsbyImage className="c-case-hero__pic c-pic" image={$case.case_image.gatsbyImageData} />
              }

              <svg className="c-case-hero__mask--xl" enableBackground="new 0 0 1920 58" height={58} viewBox="0 0 1920 58" width={1920} xmlns="http://www.w3.org/2000/svg"><path d="m1920 0c-284.77 30.31-612.03 47.56-960.12 47.56-348 0-675.16-17.24-959.88-47.53v57.97h1920z" fill="#fff" /></svg>
            </div> {/* .c-case-hero__holder */}
          </div> {/* .c-case-hero */}




          {/*
          <div className="c-case-tech c-case-tech--outside o-wrapper o-section--b">
            <h3 className="c-case-tech__title u-alt-font--bold">Technologies &amp; Tools:</h3>
            <ul className="c-case-tech__list">
              <li className="c-case-tech__item" style={{ fontSize: '113px' }}>
                <img src="assets/images/temp/tech/tech-1.png" width={113} height={52} title="(Nombre)" alt="(Nombre)" className="c-case-tech__logo" />
              </li>
            </ul> 
          </div>
                */}


          {/* BASIC INFO */}
          <section className="c-case__info c-case-info o-wrapper" aria-label="Project information">
            <h2 className="u-hidden-for-seo">Project information</h2>
            <div className="c-case-info__row">

              {$case.info_description && $case.info_description.richText &&
                <div className="c-case-info__col c-case-info__col--lg">
                  <h3 className="c-case-info__title">Description</h3>
                  <RichText render={$case.info_description.richText} />
                </div>
              }

              {$case.info_industry &&
                <div className="c-case-info__col">
                  <h3 className="c-case-info__title">Industry</h3>
                  <p>{$case.info_industry}</p>
                </div>
              }


              {$case.client_name &&
                <div className="c-case-info__col">
                  <h3 className="c-case-info__title">Client</h3>
                  <p>{$case.client_name}</p>
                </div>
              }

              {$case.info_clutch &&
                <div className="c-case-info__col" data-stars={parseInt($case.info_clutch)}>
                  <h3 className="c-case-info__title">Clutch Review</h3>
                  <p>
                    <span className="c-case-info__star icon-star" />
                    <span className="c-case-info__star icon-star" />
                    <span className="c-case-info__star icon-star" />
                    <span className="c-case-info__star icon-star" />
                    <span className="c-case-info__star icon-star" />
                    {$case.info_clutch}
                  </p>
                </div>
              }


              {$case.info_release &&
                <div className="c-case-info__col">
                  <h3 className="c-case-info__title">Release</h3>
                  <p>{$case.info_release}</p>
                </div>
              }

            </div> {/* .c-case-info__row */}
          </section> {/* .c-case-info */}


          <div className="s-case o-wrapper">

            <div className="u-spacing--xl"></div>


            {$case.body && <SliceZone slices={$case.body} components={components} />}

            {$case.case_testimony_title &&
              <div className="c-content c-content--center o-section--t">
                <h3 className="c-content__title">{$case.case_testimony_title}</h3>
              </div>
            }

            {$caseTestimony &&
              <>
                <div className="u-spacing" />
                <div className="c-case__quote c-quote">
                  <div className="c-quote__holder">
                    <div className="c-quote__quote-holder">

                      <blockquote className="c-quote__quote u-text">
                        {$caseTestimony.testimony_quote && <RichText render={$caseTestimony.testimony_quote.richText} />}
                        {$caseTestimony.testimony_content && <RichText render={$caseTestimony.testimony_content.richText} />}
                      </blockquote> {/* .c-quote__quote */}

                      {$caseTestimony.testimony_link && $caseTestimony.testimony_link.url &&
                        <div className="c-quote__link-holder c-quote__link-holder@md">
                          <Link to={$caseTestimony.testimony_link.url} target="_blank" rel="noopener" className="c-quote__link c-link u-tap-size">{$caseTestimony.testimony_linktitle}<span className="c-link__icon" /></Link>
                        </div>
                      }

                    </div> {/* .c-quote__quote-holder */}

                    {$caseClient &&
                      <div className="c-quote__client c-quote-client">
                        <div className="c-quote-client__logo">
                          {$caseClient.primary.client_logo.url && $caseClient.primary.client_name && 
                            ImgResolver($caseClient.primary.client_logo, $caseClient.primary.client_company, 'c-quote-client__img')
                          }
                        </div>
                        <cite className="c-quote-client__text">
                          {$caseClient.primary.client_name && <strong className="c-quote-client__name u-alt-font--bold">{$caseClient.primary.client_name}</strong>}
                          {$caseClient.primary.client_job && <span className="c-quote-client__job u-alt-font">{$caseClient.primary.client_job}</span>}
                          <img src="/assets/images/static/quotes/stars-5-blue.svg" width={150} height={26} alt="5 stars" title="5 stars" className="c-quote-client__stars" loading="lazy" />
                        </cite>
                      </div>
                    }

                    {$caseTestimony.testimony_link && $caseTestimony.testimony_link.url &&
                      <div className="c-quote__link-holder c-quote__link-holder@xs">
                        <Link to={$caseTestimony.testimony_link.url} target="_blank" rel="noopener" className="c-quote__link c-link u-tap-size">{$caseTestimony.testimony_linktitle}<span className="c-link__icon" /></Link>
                      </div>
                    }


                  </div> {/* .c-quote__holder */}
                </div>
              </>
            }

          </div> {/* .s-case */}


          {/* SHARE */}
          <aside className="c-case__share c-case-share o-wrapper o-section--t">
            <h2 className="c-case-share__title u-text">Share project on</h2>
            <ul className="c-case-share__social c-social c-social--cta">
              <li className="c-social__item">
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${href}`} target="_blank" rel="noopener" className="c-social__link u-tap-size icon-social-facebook" title="Facebook">
                  <span className="u-hidden-for-seo">Facebook</span>
                </a>
              </li>
              <li className="c-social__item">
                <a href={`https://www.linkedin.com/shareArticle?url=${href}`} target="_blank" rel="noopener" className="c-social__link u-tap-size icon-social-linkedin" title="LinkedIn">
                  <span className="u-hidden-for-seo">LinkedIn</span>
                </a>
              </li>
              <li className="c-social__item">
                <a href={`https://twitter.com/intent/tweet?url=${href}&text=${$case.case_title} Case study&via=createthrivedev`} target="_blank" rel="noopener" className="c-social__link u-tap-size icon-social-twitter" title="Twitter">
                  <span className="u-hidden-for-seo">Twitter</span>
                </a>
              </li>
              <li className="c-social__item">
                <a href={`https://api.whatsapp.com/send?text=${$case.case_title} Case study: ${href}`} target="_blank" rel="noopener" className="c-social__link u-tap-size icon-social-whatsapp" title="Whatsapp">
                  <span className="u-hidden-for-seo">Whatsapp</span>
                </a>
              </li>
            </ul> {/* .c-social */}
          </aside> {/* .c-footer__col */}


        </article> {/* .c-about */}


        <nav className="c-case-nav o-section o-wrapper o-wrapper--xl">

          <h3 className="u-hidden-for-seo">Other case studies</h3>
          <ul className="c-case-nav__list">


            {pageContext.next && pageContext.next.data &&
              <li className="c-case-nav__item">
                <article className="c-case-nav__project c-case-project">
                  <div className="c-case-project__image">
                    <div className="c-case-project__bg" style={{ backgroundColor: `#${pageContext.next.data.case_color}` }} />
                    <div className="c-case-project__pic-holder">

                      {pageContext.next.data.case_image && pageContext.next.data.case_image.url &&
                        <GatsbyImage className="c-case-project__pic c-pic" image={pageContext.next.data.case_image.gatsbyImageData} />
                      }

                    </div> {/* .c-case-project__pic-holder */}
                  </div> {/* .c-case-project__image */}
                  <div className="c-case-project__info">
                    <p className="c-case-project__kicker c-heading__kicker u-alt-font--bold">Next project</p>
                    <h4 className="c-case-project__title u-alt-font--bold"><Link to={pageContext.next.url} className="u-tap-size" tabIndex={-1}>{pageContext.next.data.case_title}{pageContext.next.data.hero_title.text ? ': ' + pageContext.next.data.hero_title.text : ''}</Link></h4>

                    <Link to={pageContext.next.url} className="c-case-project__link c-link u-tap-size">{pageContext.next.data.case_linktitle}<span className="c-btn__icon" /></Link>

                  </div> {/* .c-case-project__info */}
                </article> {/* .c-case-project */}
              </li>
            }

            {pageContext.previous && pageContext.previous.data &&
              <li className="c-case-nav__item">
                <article className="c-case-nav__project c-case-project">
                  <div className="c-case-project__image">
                    <div className="c-case-project__bg" style={{ backgroundColor: `#${pageContext.previous.data.case_color}` }} />
                    <div className="c-case-project__pic-holder">

                      {pageContext.previous.data.case_image && pageContext.previous.data.case_image.url &&
                        <GatsbyImage className="c-case-project__pic c-pic" image={pageContext.previous.data.case_image.gatsbyImageData} />
                      }

                    </div> {/* .c-case-project__pic-holder */}
                  </div> {/* .c-case-project__image */}
                  <div className="c-case-project__info">
                    <p className="c-case-project__kicker c-heading__kicker u-alt-font--bold">Previous project</p>
                    <h4 className="c-case-project__title u-alt-font--bold"><Link to={pageContext.previous.url} className="u-tap-size" tabIndex={-1}>{pageContext.previous.data.case_title}{pageContext.previous.data.hero_title ? ': ' + pageContext.previous.data.hero_title.text : '' }</Link></h4>

                    <Link to={pageContext.previous.url} className="c-case-project__link c-link u-tap-size">{pageContext.previous.data.case_linktitle}<span className="c-btn__icon" /></Link>

                  </div> {/* .c-case-project__info */}
                </article> {/* .c-case-project */}
              </li>
            }




          </ul> {/* .c-case-nav__list */}
        </nav>

      </main>

    </Layout>


  )
}

export const query = graphql`
  query CaseQuery($id: String) {
    prismicCase (id: { eq: $id }){
      id
      uid
      data {
        case_color
        case_linktitle
        case_testimony_title
        case_title
        info_industry
        info_release
        info_clutch
        hero_gradient_background
        client_name
        page_keywords
        page_avatar {
          url
          gatsbyImageData(
            
            breakpoints: [414,767,768,980,1024,1440,1680,1920]
          )
        }
        case_description {
          richText
        }
        hero_image {
          url
          gatsbyImageData(
            
            breakpoints: [414,767,768,980,1024,1440,1680,1920]
          )
        }
        hero_title {
          richText
        }
        info_description {
          richText
        }
        case_filters {
          case_filter {
            uid
            document {
              ... on PrismicFilter {
                id
                uid
                data {
                  filter_name
                }
              }
            }
          }
        }
        case_image {
          url
          gatsbyImageData(
            
            breakpoints: [414,767,768,980,1024,1440,1680,1920]
          )
        }
        case_testimony {
          document {
            ... on PrismicTestimony {
              data {
                testimony_content {
                  richText
                }
                testimony_link {
                  url
                }
                testimony_linktitle
                testimony_quote {
                  richText
                }
                body {
                  ... on PrismicTestimonyDataBodyClient {
                    primary {
                      client_company
                      client_job
                      client_logo {
                        dimensions {
                          height
                          width
                        }
                        url
                      }
                      client_name
                    }
                  }
                }
              }
            }
          }
        }
      
        body {
          ... on PrismicCaseDataBodyFullscreenImage {
            slice_type
          }
          ...PostDataBodyFullscreenImage
          ... on PrismicCaseDataBodyHeading {
            slice_type
          }
          ...PostDataBodyHeading
          ... on PrismicCaseDataBodyIconList {
            slice_type
          }
          ...PostDataBodyIconList
          ... on PrismicCaseDataBodyNumberList {
            slice_type
          }
          ...PostDataBodyNumberList
          ... on PrismicCaseDataBodyResult {
            slice_type
          }
          ...PostDataBodyResult
          ... on PrismicCaseDataBodySeparator {
            slice_type
          }
          ... on PrismicCaseDataBodySeparator1 {
            slice_type
          }
          ... on PrismicCaseDataBodySeparatorXl {
            slice_type
          }
          ... on PrismicCaseDataBodySideList {
            slice_type
          }
          ...PostDataBodySideList
          ... on PrismicCaseDataBodyTwoColumns {
            slice_type
          }
          ...PostDataBodyTwoColumns

          ... on PrismicCaseDataBodyInfoCarousel {
            slice_type
          }
          ...PostDataBodyInfoCarousel
          ... on PrismicCaseDataBodySimpleCarousel {
            slice_type
          }
          ...PostDataBodySimpleCarousel

        }
      }
    },
    prismicMetadata {
      ...MetadataFragment
    },   
    prismicClutch {
      ...ClutchFragment
    },
    prismicFooter{
      ...FooterFragment   
    }
    prismicSocials{
      ...SocialsFragment   
    }
    prismicContactUs{
      ...ContactUsFragment   
    }
    prismicCalltoaction{
      ...CallToActionFragment  
    }
    
  }
`

export default Case

