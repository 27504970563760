import * as React from 'react'
import { graphql } from 'gatsby'
import RichText from '../../components/richText';
import { GatsbyImage } from "gatsby-plugin-image"
import ImgResolver from '../../utils/imgResolver'


export const IconList = ({ slice }) => {

  return (

    slice.items.length > 0 &&
    <ul className="c-icon-list">
      {slice.items.map((item, i) => {

        return (
          <li key={i} className="c-icon-list__item">
            {item.icon_list_image && item.icon_list_image.url &&
              ImgResolver(item.icon_list_image, item.icon_list_title, 'c-icon-list__icon')
            }
            <h4 className="c-icon-list__title">{item.icon_list_title}</h4>
            {item.icon_list_content && <RichText render={item.icon_list_content.richText} />}
          </li>
        )
      })}
    </ul>

  )

}


export const query = graphql`
  fragment PostDataBodyIconList on PrismicCaseDataBodyIconList {
    items {
      icon_list_content {
        richText
      }
      icon_list_image {
        url
        dimensions {
          height
          width
        }
      }
      icon_list_title
    }
  }
`
