import * as React from 'react'
import { graphql } from 'gatsby'
import RichText from '../../components/richText';


export const NumberList = ({ slice }) => {

  return (

    slice.items.length > 0 &&
    <ol className="c-number-list">
      {slice.items.map((item, i) => {
        return (
          <li key={i} className="c-number-list__item">
            <h4 className="c-number-list__title">
              <span className="c-number-list__num">{i + 1}.</span>
              {item.number_list_icon}
            </h4>
            {item.number_list_content && <RichText render={item.number_list_content.richText} />}
          </li>
        )
      })}
    </ol>
  )

}


export const query = graphql`
  fragment PostDataBodyNumberList on PrismicCaseDataBodyNumberList {
    items {
      number_list_icon
      number_list_content {
        richText
      }
    }
  }
`
