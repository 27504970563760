import * as React from 'react';

export const SeparatorMD = () => {

  return (

    <div className="u-spacing--md"></div>

  )

}