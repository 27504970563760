import * as React from 'react';

export const Separator = () => {

  return (

    <div className="u-spacing"></div>

  )

}