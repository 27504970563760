import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"


export const FullscreenImage = ({ slice }) => {

  return (

    slice.primary.full_image && slice.primary.full_image.url &&
    <div className="c-fullscreen-img c-pic">
      <GatsbyImage image={slice.primary.full_image.gatsbyImageData} />
    </div>
    
  )

}
export const query = graphql`
  fragment PostDataBodyFullscreenImage on PrismicCaseDataBodyFullscreenImage {
    primary {
      full_image {
        gatsbyImageData(
          
          breakpoints: [414,767,768,980,1024,1440,1680,1920]
        )
        url
      }
    }
  }
`