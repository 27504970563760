import * as React from 'react';

export const SeparatorXL = () => {

  return (

    <div className="u-spacing--xl"></div>

  )

}