import { SeparatorXL } from "./separator-xl";
import { Separator } from "./separator";
import { SeparatorMD } from "./separator-md";
import { TwoColumns } from "./two-columns";
import { Heading } from "./heading";
import { FullscreenImage } from "./fullscreen-image";
import { IconList } from "./icon-list";
import { NumberList } from "./number-list";
import { SideList } from "./side-list";
import { Result } from "./result";
import { InfoCarousel } from "./info-carousel";
import { SimpleCarousel } from "./simple-carousel";


export const components = {
  separator_xl: SeparatorXL,
  separator_md: SeparatorMD,
  separator1: Separator,
  two_columns: TwoColumns,
  heading: Heading,
  fullscreen_image: FullscreenImage,
  icon_list: IconList,
  number_list: NumberList,
  side_list: SideList,
  result: Result,
  info_carousel: InfoCarousel,
  simple_carousel: SimpleCarousel,

}