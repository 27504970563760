import * as React from 'react'
import { graphql } from 'gatsby'
import RichText from '../../components/richText';


export const Heading = ({ slice }) => {

  return (

    <div className={`c-content ${slice.primary.heading_center ? 'c-content--center' : ''}`}>
      {slice.primary.heading_kicker ?
        <>
          <h3 className="c-content__kicker">{slice.primary.heading_kicker}</h3>
          <p className="c-content__heading">{slice.primary.heading_title}</p>
        </>
        :
        <h3 className="c-content__heading">{slice.primary.heading_title}</h3>
      }
      <RichText render={slice.primary.heading_content.richText} />

    </div>

  )

}


export const query = graphql`
  fragment PostDataBodyHeading on PrismicCaseDataBodyHeading {
    primary {
      heading_center
      heading_content {
        richText
      }
      heading_kicker
      heading_title
    }
  }
`