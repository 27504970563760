import * as React from 'react'
import { graphql } from 'gatsby'
import RichText from '../../components/richText';
import { GatsbyImage } from "gatsby-plugin-image"


export const InfoCarousel = ({ slice }) => {

  return (


    slice.items.length > 0 &&
    <div className="c-info-carousel js-info-carousel js-carousel__notInit">
      <ul className="c-info-carousel__list js-carousel">
        {slice.items.map((item, i) => {

          return (
            <li key={i} className={`c-info-carousel__item ${i != 0 ? 'c-info-carousel__item--notInitHide' : ''}`}>
              <div className="c-info-carousel__holder">

                {item.info_carousel_image && item.info_carousel_image.url &&
                <div className="c-info-carousel__pic-holder">
                  <GatsbyImage className="c-info-carousel__pic c-pic" image={item.info_carousel_image.gatsbyImageData} /> 
                </div>}

                <div className="c-info-carousel__info c-content">
                  <div className="c-info-carousel__nums">
                    <span className="c-info-carousel__num">{i + 1}</span>
                    <span className="c-info-carousel__num">{slice.items.length}</span>
                  </div> {/* .c-info-carousel__nums */}
                  {item.info_carousel_title && <h4 className="c-info-carousel__title c-content__title">{item.info_carousel_title}</h4>}
                  {item.info_carousel_subtitle && <p className="c-info-carousel__subtitle">{item.info_carousel_subtitle}</p>}
                  
                  <RichText render={item.info_carousel_content.richText} />

                </div> {/* .c-info-carousel__info */}
              </div> {/* .c-info-carousel__holder */}
            </li>
          )
        })}

      </ul> {/* .c-info-carousel__list */}
    </div>

  )

}


export const query = graphql`
  fragment PostDataBodyInfoCarousel on PrismicCaseDataBodyInfoCarousel {
    items {
      info_carousel_content {
        richText
      }
      info_carousel_image {
        gatsbyImageData(
          
          breakpoints: [414,767,768,980,1024,1440,1680,1920]
        )
        url
      }

      info_carousel_subtitle
      info_carousel_title

    }
  }
`

