import * as React from 'react'
import { graphql } from 'gatsby'
import RichText from '../../components/richText';


export const Result = ({ slice }) => {

  return (


    <div className="c-result c-content">
      <h3 className="c-content__heading">{slice.primary.result_title}</h3>
      {slice.primary.result_content && <RichText render={slice.primary.result_content.richText} />}
    </div>

  )

}


export const query = graphql`
  fragment PostDataBodyResult on PrismicCaseDataBodyResult {
    primary {
      result_title
      result_content {
        richText
      }
    }
  }
`
