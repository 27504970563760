import * as React from 'react'
import { graphql } from 'gatsby'
import RichText from '../../components/richText';
import { GatsbyImage } from "gatsby-plugin-image"


export const TwoColumns = ({ slice }) => {

  return (

    <div className={`c-two-column ${slice.primary.image_left ? '' : 'c-two-column--img-right'} `}>

      <div className="c-two-column__col">

        {slice.primary.two_cols_image && slice.primary.two_cols_image.url &&
          <GatsbyImage className="c-pic" image={slice.primary.two_cols_image.gatsbyImageData} />
        }

      </div> {/* .c-two-column__col */}

      <div className="c-two-column__col">
        <div className="c-content">
          {slice.primary.two_cols_heading && <h3 className="c-content__heading">{slice.primary.two_cols_heading}</h3>}
          {slice.primary.two_cols_content && <RichText render={slice.primary.two_cols_content.richText} />}
        </div> {/* .c-case__content */}
      </div> {/* .c-two-column__col */}
    </div>

  )

}


export const query = graphql`
  fragment PostDataBodyTwoColumns on PrismicCaseDataBodyTwoColumns {
    primary {
      image_left
      two_cols_content {
        richText
      }
      two_cols_heading
      two_cols_image {
        gatsbyImageData(
          
          breakpoints: [414,767,768,980,1024,1440,1680,1920]
        )
        url
      }
    }
  }
`

